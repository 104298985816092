<template>
  <div class="pa-3">
    <v-btn
      rounded
      :to="`/list/user/${$route.params.role}`"
      depressed
      class="mb-3"
      color="green"
      fab
      dark
      small
      ><v-icon>mdi-reply</v-icon></v-btn
    >
    <div class="d-flex justify-center">
      <div class="half_width__">
        <h1 class="text-center text-capitalize mb-5">
          create new {{ label }}
        </h1>
        <CreateAdmin v-if="$route.params.role == 'data_control'" />
        <CreateTf v-if="$route.params.role == 'tf'" />
        <CreateCounselor v-if="$route.params.role == 'admin_assessment'" />
        <CreateScholar v-if="$route.params.role == 'scholar'" />
      </div>
    </div>
  </div>
</template>

<script>
import CreateAdmin from "../../../components/UserManagement/Create/createAdmin.vue";
import CreateCounselor from "../../../components/UserManagement/Create/createCounselor.vue";
import CreateScholar from "../../../components/UserManagement/Create/createScholar.vue";
import CreateTf from "../../../components/UserManagement/Create/createTf.vue";
export default {
  components: { CreateAdmin, CreateTf, CreateScholar, CreateCounselor },
  name: "createUser",
  data() {
    return{
      label : '',
    }
  },
  mounted() {
    let route = this.$route.params.role;
    switch (route) {
      case "data_control":
        this.label = "Data Control";
        break;
      case "tf":
        this.label = "Admin TF";
        break;
      case "admin_assessment":
        this.label = "Admin Assessment";
        break;
      case "scholar":
        this.label = "Scholar";
        break;
    }
  },
};
</script>

<style></style>
