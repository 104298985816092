<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">Nama Lengkap</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="name"
          hide-details="auto"
          label="Nama lengkap"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Tanggal Lahir</v-col>
      <v-col cols="12" md="8">
        <v-menu
          v-model="calendar1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="birth_date"
              label="Tanggal Lahir"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="birth_date"
            @input="calendar1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Jenis Kelamin</v-col>
      <v-col cols="12" md="8">
        <v-radio-group v-model="gender" row hide-details="auto" class="mt-0">
          <v-radio label="Laki-laki" value="laki-laki"></v-radio>
          <v-radio label="Perempuan" value="perempuan"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Email</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="email"
          hide-details="auto"
          label="Email"
          :error-messages="emailErr"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Password</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="password"
          hide-details="auto"
          label="Password"
          :error-messages="pwdErr"
          required
          dense
        ></v-text-field>
        <p
          style="line-height: 1; font-size: small"
          class="ma-0 mt-1"
          v-if="!pwdErr"
        >
          password minimal 8 karakter dan harus mengandung : huruf kecil, huruf
          besar, angka, dan karakter spesial
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">No Hp</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="no_hp"
          hide-details="auto"
          label="No Hp"
          type="number"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Nim</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="nim"
          hide-details="auto"
          label="Nim"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Nik</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="nik"
          type="number"
          hide-details="auto"
          label="Nik"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">NO. TELADAN</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="noTf"
          hide-details="auto"
          label="No TF"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Universitas</v-col>
      <v-col cols="12" md="8">
        <v-select
          v-model="university"
          :items="listUniv"
          menu-props="auto"
          label="Universitas"
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Fakultas</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="faculty"
          hide-details="auto"
          label="Fakultas"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Jurusan</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="field"
          hide-details="auto"
          label="Jurusan"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Tahun PTN</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="tahun_ptn"
          hide-details="auto"
          label="Tahun PTN"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Tahun TF</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="tahun_tf"
          hide-details="auto"
          label="Tahun TF"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Program</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="program"
          hide-details="auto"
          label="Program"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester Daftar</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="semester_daftar"
          hide-details="auto"
          label="Semester Daftar"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester Sekarang</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="semester_sekarang"
          hide-details="auto"
          label="Semester Sekarang"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Assessment</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="assessment"
          hide-details="auto"
          label="Assessment"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Grade</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="grade"
          hide-details="auto"
          label="Grade"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 1</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="semester1"
          hide-details="auto"
          label="Semester 1"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 2</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="semester2"
          hide-details="auto"
          label="Semester 2"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 3</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="semester3"
          hide-details="auto"
          label="Semester 3"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 4</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="semester4"
          hide-details="auto"
          label="Semester 4"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 5</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="semester5"
          hide-details="auto"
          label="Semester 5"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 6</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="semester6"
          hide-details="auto"
          label="Semester 6"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 7</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="semester7"
          hide-details="auto"
          label="Semester 7"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 8</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="semester8"
          hide-details="auto"
          label="Semester 8"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Provinsi</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="provinsi"
          hide-details="auto"
          label="Provinsi"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Kota</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="kota"
          hide-details="auto"
          label="Kota"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Kode Pos</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="kode_pos"
          hide-details="auto"
          label="Kode Pos"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Tempat Lahir</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="tempat_lahir"
          hide-details="auto"
          label="Tempat Lahir"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Alamat</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="alamat"
          hide-details="auto"
          label="Tempat Lahir"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Agama</v-col>
      <v-col cols="12" md="8">
        <v-select
          v-model="agama"
          :items="listReligion"
          menu-props="auto"
          label="Agama"
          hide-details
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Etnis</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="etnis"
          hide-details="auto"
          label="Etnis"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Grouping Fakultas</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="grouping_fakultas"
          hide-details="auto"
          label="Grouping Fakultas"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Nama Rekening</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="nama_rekening"
          hide-details="auto"
          label="Nama Rekening"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Nama Bank</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="nama_bank"
          hide-details="auto"
          label="Nama Bank"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">No Rekening</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="no_rekening"
          hide-details="auto"
          label="No Rekening"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Jumlah Tunjangan Perbulan</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="jumlah_tunjangan_bulanan"
          hide-details="auto"
          label="Jumlah Tunjangan Perbulan"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Tagihan UKT</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="tagihan_ukt"
          hide-details="auto"
          label="Tagihan UKT"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Ukuran Kaos</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="ukuran_kaos"
          hide-details="auto"
          label="Ukuran Kaos"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Tsg</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="tsg"
          hide-details="auto"
          label="tsg"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <!-- =============================================NEW FORM ============================================================================= -->
    <v-row>
      <v-col cols="12" md="4">Kode Unik</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="kode_unik"
          hide-details="auto"
          label="Kode Unik"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Jalur Masuk Ptn</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="jalur_masuk_ptn"
          hide-details="auto"
          label="Jalur Masuk Ptn"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">No Wa</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="no_hp_wa"
          hide-details="auto"
          label="No Wa"
          type="number"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Kelurahan Desa</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="kelurahan_desa"
          hide-details="auto"
          label="Kelurahan Desa"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Negara</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="negara"
          hide-details="auto"
          label="Negara"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Akun Sosial Media</v-col>
      <v-col cols="12" md="8">
        <div class="scope_lang">
          <v-row
            v-for="(item, idx) in akun_sosial_media"
            :key="`sosmed-${idx}`"
          >
            <v-col cols="3">
              {{ item.name }}
            </v-col>
            <v-col cols="9">
              <v-text-field
                v-model="item.value"
                hide-details="auto"
                :placeholder="item.name"
                required
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Nama Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="nama_ayah_wali"
          hide-details="auto"
          label="Nama Ayah Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Tempat Lahir Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="tempat_lahir_ayah_wali"
          hide-details="auto"
          label="Tempat Lahir Ayah Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Tanggal Lahir Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-menu
          v-model="calendar2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="tanggal_lahir_ayah_wali"
              label="Tanggal Lahir Ayah Wali"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="tanggal_lahir_ayah_wali"
            @input="calendar2 = false"
          ></v-date-picker> </v-menu
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Pekerjaan Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="pekerjaan_ayah_wali"
          hide-details="auto"
          label="Pekerjaan Ayah Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Penghasilan Kotor Perbulan Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="penghasilan_kotor_perbulan_ayah_wali"
          hide-details="auto"
          label="Penghasilan Kotor Perbulan Ayah Wali"
          required
          type="number"
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Pendidikan Terakhir Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="pendidikan_terakhir_ayah_wali"
          hide-details="auto"
          label="Pendidikan Terakhir Ayah_wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Email Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="email_ayah_wali"
          hide-details="auto"
          label="Email Ayah Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">No Hp Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="no_hp_ayah_wali"
          hide-details="auto"
          label="No Hp Ayah Wali"
          type="number"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Nama Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="nama_ibu_wali"
          hide-details="auto"
          label="Nama Ibu Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Tempat Lahir Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="tempat_lahir_ibu_wali"
          hide-details="auto"
          label="Tempat Lahir Ibu Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Tanggal Lahir Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-menu
          v-model="calendar3"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="tanggal_lahir_ibu_wali"
              label="Tanggal Lahir Ibu Wali"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="tanggal_lahir_ibu_wali"
            @input="calendar3 = false"
          ></v-date-picker> </v-menu
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Pekerjaan Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="pekerjaan_ibu_wali"
          hide-details="auto"
          label="Pekerjaan Ibu Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Penghasilan Kotor Perbulan Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="penghasilan_kotor_perbulan_ibu_wali"
          hide-details="auto"
          label="Penghasilan Kotor Perbulan Ibu Wali"
          required
          type="number"
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Pendidikan Terakhir Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="pendidikan_terakhir_ibu_wali"
          hide-details="auto"
          label="Pendidikan Terakhir Ibu Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Email Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="email_ibu_wali"
          hide-details="auto"
          label="Email Ibu Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">No Hp Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="no_hp_ibu_wali"
          hide-details="auto"
          label="No Hp Ibu Wali"
          required
          type="number"
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Jumlah Tanggungan Orangtua</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="jumlah_tanggungan_orangtua"
          hide-details="auto"
          label="Jumlah Tanggungan Orangtua"
          required
          type="number"
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Prestasi</v-col>
      <v-col cols="12" md="8">
        <div class="scope_lang">
          <div
            v-for="(item, idx) in prestasi"
            class="mb-3"
            :key="`pres-${idx}`"
          >
            <v-text-field
              v-model="item.val"
              hide-details="auto"
              :placeholder="`Prestasi ${idx + 1}`"
              required
              dense
            ></v-text-field>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Kegiatan Organisasi</v-col>
      <v-col cols="12" md="8">
        <div class="scope_lang">
          <div
            class="mb-3"
            v-for="(item, idx) in kegiatan_organisasi"
            :key="`pres-${idx}`"
          >
            <v-text-field
              v-model="item.val"
              hide-details="auto"
              :placeholder="`Kegiatan Organisasi ${idx + 1}`"
              required
              dense
            ></v-text-field>
          </div></div
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Kemampuan Bahasa Asing</v-col>
      <v-col cols="12" md="8">
        <div
          class="scope_lang"
          v-for="(item, idx) in kemampuan_bahasa_asing"
          :key="`val${idx}`"
        >
          <v-row>
            <v-col cols="4"> Bahasa </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item.bahasa"
                placeholder="Bahasa"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> Memiliki Sertifikasi </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item['Memiliki Sertifikasi']"
                placeholder="Memiliki Sertifikasi "
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> Jenis Tes </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item['Jenis Tes']"
                placeholder="Jenis Tes"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> Skor </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item.Skor"
                placeholder="Skor"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> Tahun Srtifikat </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item['Tahun Srtifikat']"
                placeholder="Tahun Srtifikat"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> No. Sertifikat </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item['No. Sertifikat']"
                placeholder="No. Sertifikat"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> Nama Lembaga Bahasa </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item['Nama Lembaga Bahasa']"
                placeholder="Nama Lembaga Bahasa "
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> Masa Berlaku </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item['Masa Berlaku']"
                placeholder="Masa Berlaku"
                required
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Scan Ktm</v-col>
      <v-col cols="12" md="8"
        ><v-file-input
          v-model="scan_ktm"
          hide-details="auto"
          label="Scan Ktm"
        ></v-file-input
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Scan Ktp</v-col>
      <v-col cols="12" md="8"
        ><v-file-input
          v-model="scan_ktp"
          hide-details="auto"
          label="Scan Ktp"
        ></v-file-input
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Scan Kk</v-col>
      <v-col cols="12" md="8"
        ><v-file-input
          v-model="scan_kk"
          hide-details="auto"
          label="Scan Kk"
        ></v-file-input
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Scan Slip Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-file-input
          v-model="scan_slip_ayah_wali"
          label="Scan Slip Ayah Wali"
          hide-details="auto"
        ></v-file-input
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Scan Slip Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-file-input
          v-model="scan_slip_ibu_wali"
          label="Scan Slip Ibu Wali"
          hide-details="auto"
        ></v-file-input
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Scan Rapor</v-col>
      <v-col cols="12" md="8">
        <v-file-input
          v-model="scan_rapor"
          hide-details="auto"
          label="Scan Rapor"
        ></v-file-input
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Scan Ijazah</v-col>
      <v-col cols="12" md="8">
        <v-file-input
          v-model="scan_ijazah"
          hide-details="auto"
          label="Scan Ijazah"
        ></v-file-input
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Scan Sertifikat Prestasi</v-col>
      <v-col cols="12" md="8">
        <v-file-input
          v-model="scan_sertifikat_prestasi"
          hide-details="auto"
          label="Scan Sertifikat Prestasi"
        ></v-file-input
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Scan Sertifikat Organisasi</v-col>
      <v-col cols="12" md="8"
        ><v-file-input
          v-model="scan_sertifikat_organisasi"
          hide-details="auto"
          label="Scan Sertifikat Organisasi"
        ></v-file-input
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Scan Sertifikat Bahasa Asing</v-col>
      <v-col cols="12" md="8"
        ><v-file-input
          v-model="scan_sertifikat_bahasa_asing"
          hide-details="auto"
          label="Scan Sertifikat Bahasa Asing"
        ></v-file-input
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Scan Pas Foto</v-col>
      <v-col cols="12" md="8"
        ><v-file-input
          v-model="scan_pas_foto"
          @change="checking"
          hide-details
          class="ma-0 pa-0"
          label="Scan Pas Foto"
        ></v-file-input
      ></v-col>
    </v-row>

    <footer class="d-flex justify-end my-5">
      <v-btn
        color="green"
        class="text-capitalize"
        dark
        v-if="!loading"
        @click="createItem"
        >Save</v-btn
      >
      <v-btn v-if="loading" color="green" class="text-capitalize" dark depressed
        ><v-progress-circular
          :size="25"
          class="mr-2"
          indeterminate
        ></v-progress-circular>
        Loading</v-btn
      >
    </footer>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "createScholar",
  data() {
    return {
      name: "",
      password: "",
      email: "",
      birth_date: "",
      gender: "",
      adress: "",
      no_hp: "",
      nim: "",
      nik: "",
      noTf: "",
      last_education: "",
      faculty: "",
      field: "",
      university: "",
      tahun_ptn: "",
      tahun_tf: "",
      program: "",
      semester_daftar: "",
      semester_sekarang: "",
      assessment: "",
      grade: "",
      semester1: "",
      semester2: "",
      semester3: "",
      semester4: "",
      semester5: "",
      semester6: "",
      semester7: "",
      semester8: "",
      provinsi: "",
      kota: "",
      kode_pos: "",
      tempat_lahir: "",
      alamat: "",
      agama: "",
      etnis: "",
      grouping_fakultas: "",
      nama_rekening: "",
      nama_bank: "",
      no_rekening: "",
      jumlah_tunjangan_bulanan: "",
      tagihan_ukt: "",
      ukuran_kaos: "",
      tsg: "",
      // FIELD BARU YA AMPUN NAMBAHNYA BANYAK BANGET
      kode_unik: "",
      jalur_masuk_ptn: "",
      no_hp_wa: "",
      kelurahan_desa: "",
      negara: "",
      akun_sosial_media: [
        {
          name: "Instagram",
          value: "",
        },
        {
          name: "Linkedin",
          value: "",
        },
        {
          name: "Facebook",
          value: "",
        },
        {
          name: "Youtube",
          value: "",
        },
        {
          name: "Twitter",
          value: "",
        },
      ],
      nama_ayah_wali: "",
      tempat_lahir_ayah_wali: "",
      tanggal_lahir_ayah_wali: "",
      pekerjaan_ayah_wali: "",
      penghasilan_kotor_perbulan_ayah_wali: "",
      pendidikan_terakhir_ayah_wali: "",
      email_ayah_wali: "",
      no_hp_ayah_wali: "",
      nama_ibu_wali: "",
      tempat_lahir_ibu_wali: "",
      tanggal_lahir_ibu_wali: "",
      pekerjaan_ibu_wali: "",
      penghasilan_kotor_perbulan_ibu_wali: "",
      email_ibu_wali: "",
      no_hp_ibu_wali: "",
      pendidikan_terakhir_ibu_wali: "",
      jumlah_tanggungan_orangtua: "",
      prestasi: [
        {
          val: "",
        },
        {
          val: "",
        },
        {
          val: "",
        },
      ],
      kegiatan_organisasi: [
        {
          val: "",
        },
        {
          val: "",
        },
        {
          val: "",
        },
      ],
      kemampuan_bahasa_asing: [
        {
          bahasa: "",
          "Memiliki Sertifikasi": "",
          "Jenis Tes": "",
          Skor: "",
          "Tahun Srtifikat": "",
          "No. Sertifikat": "",
          "Nama Lembaga Bahasa": "",
          "Masa Berlaku": "",
        },
        {
          bahasa: "",
          "Memiliki Sertifikasi": "",
          "Jenis Tes": "",
          Skor: "",
          "Tahun Srtifikat": "",
          "No. Sertifikat": "",
          "Nama Lembaga Bahasa": "",
          "Masa Berlaku": "",
        },
        {
          bahasa: "",
          "Memiliki Sertifikasi": "",
          "Jenis Tes": "",
          Skor: "",
          "Tahun Srtifikat": "",
          "No. Sertifikat": "",
          "Nama Lembaga Bahasa": "",
          "Masa Berlaku": "",
        },
      ],
      scan_ktm: null,
      scan_ktp: null,
      scan_kk: null,
      scan_slip_ayah_wali: null,
      scan_slip_ibu_wali: null,
      scan_rapor: null,
      scan_ijazah: null,
      scan_sertifikat_prestasi: null,
      scan_sertifikat_organisasi: null,
      scan_sertifikat_bahasa_asing: null,
      scan_pas_foto: null,
      // ==================rend
      calendar1: false,
      calendar2: false,
      calendar3: false,
      loading: false,
      namaErr: null,
      emailErr: null,
      pwdErr: null,
      listUniv: [
        "Institut Pertanian Bogor",
        "Institut Teknologi Bandung",
        "Universitas Andalas",
        "Universitas Brawijaya",
        "Universitas Diponegoro",
        "Universitas Gadjah Mada",
        "Universitas Indonesia",
        "Universitas Riau",
        "Universitas Sumatera Utara",
      ],
      listReligion: [
        "Buddha",
        "Hindu",
        "Islam",
        "Katolik",
        "Konghucu",
        "Kristen",
      ],
    };
  },
  methods: {
    checking() {
      console.log(this.scan_pas_foto);
    },
    createItem() {
      this.loading = true;
      let data = new FormData();
      data.append("nama_lengkap", this.name);
      data.append("email", this.email);
      data.append("password", this.password);
      data.append("tgl_lahir", this.birth_date);
      data.append("jenis_kelamin", this.gender);
      data.append("nim", this.nim);
      data.append("no_tf", this.noTf);
      data.append("no_hp", this.no_hp);
      data.append("fakultas", this.faculty);
      data.append("jurusan", this.field);
      data.append("tahun_ptn", this.tahun_ptn);
      data.append("tahun_tf", this.tahun_tf);
      data.append("program", this.program);
      data.append("semester_daftar", this.semester_daftar);
      data.append("semester_sekarang", this.semester_sekarang);
      data.append("grade", this.grade);
      data.append("semester1", this.semester1);
      data.append("semester2", this.semester2);
      data.append("semester3", this.semester3);
      data.append("semester4", this.semester4);
      data.append("semester5", this.semester5);
      data.append("semester6", this.semester6);
      data.append("semester7", this.semester7);
      data.append("semester8", this.semester8);
      data.append("provinsi", this.provinsi);
      data.append("kota", this.kota);
      data.append("kode_pos", this.kode_pos);
      data.append("tempat_lahir", this.tempat_lahir);
      data.append("alamat", this.alamat);
      data.append("agama", this.agama);
      data.append("etnis", this.etnis);
      data.append("grouping_fakultas", this.grouping_fakultas);
      data.append("nama_rekening", this.nama_rekening);
      data.append("nama_bank", this.nama_bank);
      data.append("no_rekening", this.no_rekening);
      data.append("jumlah_tunjangan_bulanan", this.jumlah_tunjangan_bulanan);
      data.append("tagihan_ukt", this.tagihan_ukt);
      data.append("ukuran_kaos", this.ukuran_kaos);
      data.append("tsg", this.tsg);
      data.append("kode_unik", this.kode_unik);
      data.append("jalur_masuk_ptn", this.jalur_masuk_ptn);
      data.append("no_hp_wa", this.no_hp_wa);
      data.append("kelurahan_desa", this.kelurahan_desa);
      data.append("akun_sosial_media", JSON.stringify(this.akun_sosial_media));
      data.append("nama_ayah_wali", this.nama_ayah_wali);
      data.append("tempat_lahir_ayah_wali", this.tempat_lahir_ayah_wali);
      data.append("tanggal_lahir_ayah_wali", this.tanggal_lahir_ayah_wali);
      data.append("pekerjaan_ayah_wali", this.pekerjaan_ayah_wali);
      data.append(
        "penghasilan_kotor_perbulan_ayah_wali",
        this.penghasilan_kotor_perbulan_ayah_wali
      );
      data.append(
        "pendidikan_terakhir_ayah_wali",
        this.pendidikan_terakhir_ayah_wali
      );
      data.append("email_ayah_wali", this.email_ayah_wali);
      data.append("no_hp_ayah_wali", this.no_hp_ayah_wali);
      data.append("nama_ibu_wali", this.nama_ibu_wali);
      data.append("tempat_lahir_ibu_wali", this.tempat_lahir_ibu_wali);
      data.append("tanggal_lahir_ibu_wali", this.tanggal_lahir_ibu_wali);
      data.append("pekerjaan_ibu_wali", this.pekerjaan_ibu_wali);
      data.append(
        "penghasilan_kotor_perbulan_ibu_wali",
        this.penghasilan_kotor_perbulan_ibu_wali
      );
      data.append(
        "pendidikan_terakhir_ibu_wali",
        this.pendidikan_terakhir_ibu_wali
      );
      data.append("email_ibu_wali", this.email_ibu_wali);
      data.append("no_hp_ibu_wali", this.no_hp_ibu_wali);
      data.append(
        "kemampuan_bahasa_asing",
        JSON.stringify(this.kemampuan_bahasa_asing)
      );

      let prestasi = [];
      this.prestasi.forEach((el) => {
        prestasi.push(el.val);
      });
      data.append("prestasi", prestasi);

      let kegiatan_organisasi = [];
      this.kegiatan_organisasi.forEach((el) => {
        kegiatan_organisasi.push(el.val);
      });
      data.append("kegiatan_organisasi", kegiatan_organisasi);

      let scan = [
        {
          name: "ktp",
          type: "file",
          file: this.scan_ktp,
        },
        {
          name: "ktm",
          type: "file",
          file: this.scan_ktm,
        },
        {
          name: "kk",
          type: "file",
          file: this.scan_kk,
        },
        {
          name: "slip_ayah_wali",
          type: "file",
          file: this.scan_slip_ayah_wali,
        },
        {
          name: "slip_ibu_wali",
          type: "file",
          file: this.scan_slip_ibu_wali,
        },
        {
          name: "rapor",
          type: "file",
          file: this.scan_rapor,
        },
        {
          name: "ijazah",
          type: "file",
          file: this.scan_ijazah,
        },
        {
          name: "sertifikat_prestasi",
          type: "file",
          file: this.scan_sertifikat_prestasi,
        },
        {
          name: "sertifikat_organisasi",
          type: "file",
          file: this.scan_sertifikat_organisasi,
        },
        {
          name: "sertifikat_bahasa_asing",
          type: "file",
          file: this.scan_sertifikat_bahasa_asing,
        },
        {
          name: "pas_foto",
          type: "file",
          file: this.scan_pas_foto,
        },
      ];
      scan.forEach((el, idx) => {
        if (el.file !== null) {
          data.append(`scan[${idx}][name]`, el.name);
          data.append(`scan[${idx}][type]`, el.type);
          data.append(`scan[${idx}][file]`, el.file);
        }
      });
      let datas = {
        body: data,
        role: this.$route.params.role,
      };
      this.$store
        .dispatch("user/createUser", datas)
        .then((data) => {
          if (data.status == "success") {
            this.name = "";
            this.email = "";
            this.password = "";
            this.no_hp = "";
            this.birth_date = "";
            this.gender = "";
            this.nim = "";
            this.nik = "";
            this.noTf = "";
            this.faculty = "";
            this.field = "";
            this.university = "";
            this.tahun_ptn = "";
            this.tahun_tf = "";
            this.program = "";
            this.semester_daftar = "";
            this.semester_sekarang = "";
            this.assessment = "";
            this.grade = "";
            this.semester1 = "";
            this.semester2 = "";
            this.semester3 = "";
            this.semester4 = "";
            this.semester5 = "";
            this.semester6 = "";
            this.semester7 = "";
            this.semester8 = "";
            this.provinsi = "";
            this.kota = "";
            this.kode_pos = "";
            this.tempat_lahir = "";
            this.alamat = "";
            this.agama = "";
            this.etnis = "";
            this.grouping_fakultas = "";
            this.nama_rekening = "";
            this.nama_bank = "";
            this.no_rekening = "";
            this.jumlah_tunjangan_bulanan = "";
            this.tagihan_ukt = "";
            this.ukuran_kaos = "";
            this.tsg = "";
            this.emailErr = null;
            this.pwdErr = null;
            // =============================================================================================================================================================
            this.kode_unik = "";
            this.jalur_masuk_ptn = "";
            this.no_hp_wa = "";
            this.kelurahan_desa = "";
            this.negara = "";
            this.akun_sosial_media = [
              {
                name: "Instagram",
                value: "",
              },
              {
                name: "Linkedin",
                value: "",
              },
              {
                name: "Facebook",
                value: "",
              },
              {
                name: "Youtube",
                value: "",
              },
              {
                name: "Twitter",
                value: "",
              },
            ];
            this.nama_ayah_wali = "";
            this.tempat_lahir_ayah_wali = "";
            this.tanggal_lahir_ayah_wali = "";
            this.pekerjaan_ayah_wali = "";
            this.penghasilan_kotor_perbulan_ayah_wali = "";
            this.pendidikan_terakhir_ayah_wali = "";
            this.email_ayah_wali = "";
            this.no_hp_ayah_wali = "";
            this.nama_ibu_wali = "";
            this.tempat_lahir_ibu_wali = "";
            this.tanggal_lahir_ibu_wali = "";
            this.pekerjaan_ibu_wali = "";
            this.penghasilan_kotor_perbulan_ibu_wali = "";
            this.email_ibu_wali = "";
            this.no_hp_ibu_wali = "";
            this.pendidikan_terakhir_ibu_wali = "";
            this.jumlah_tanggungan_orangtua = "";
            this.prestasi = [
              {
                val: "",
              },
              {
                val: "",
              },
              {
                val: "",
              },
            ];
            this.kegiatan_organisasi = [
              {
                val: "",
              },
              {
                val: "",
              },
              {
                val: "",
              },
            ];
            this.kemampuan_bahasa_asing = [
              {
                bahasa: "",
                "Memiliki Sertifikasi": "",
                "Jenis Tes": "",
                Skor: "",
                "Tahun Srtifikat": "",
                "No. Sertifikat": "",
                "Nama Lembaga Bahasa": "",
                "Masa Berlaku": "",
              },
              {
                bahasa: "",
                "Memiliki Sertifikasi": "",
                "Jenis Tes": "",
                Skor: "",
                "Tahun Srtifikat": "",
                "No. Sertifikat": "",
                "Nama Lembaga Bahasa": "",
                "Masa Berlaku": "",
              },
              {
                bahasa: "",
                "Memiliki Sertifikasi": "",
                "Jenis Tes": "",
                Skor: "",
                "Tahun Srtifikat": "",
                "No. Sertifikat": "",
                "Nama Lembaga Bahasa": "",
                "Masa Berlaku": "",
              },
            ];
            this.scan_ktm = null;
            this.scan_ktp = null;
            this.scan_kk = null;
            this.scan_slip_ayah_wali = null;
            this.scan_slip_ibu_wali = null;
            this.scan_rapor = null;
            this.scan_ijazah = null;
            this.scan_sertifikat_prestasi = null;
            this.scan_sertifikat_organisasi = null;
            this.scan_sertifikat_bahasa_asing = null;
            this.scan_pas_foto = null;
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
            this.$router.push("/list/user/scholar");
          } else {
            console.log(data.data.errors);
            this.emailErr = data.data.errors.email
              ? data.data.errors.email[0]
              : null;
            this.pwdErr = data.data.errors.password
              ? data.data.errors.password[0]
              : null;
            Swal.fire({
              icon: "error",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
          console.log(err);
        });
    },
    alert(type, title, msg) {
      this.$notify({
        group: "authVerification",
        type: type,
        title: title,
        text: msg,
      });
    },
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

</style>
