<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">Nama Lengkap</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="name"
          hide-details="auto"
          label="Nama lengkap"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Email</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="email"
          hide-details="auto"
          label="Email"
          :error-messages="emailErr"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Password</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="password"
          hide-details="auto"
          label="Password"
          :error-messages="pwdErr"
          required
          dense
        ></v-text-field>
        <p
          style="line-height: 1; font-size: small"
          class="ma-0 mt-1"
          v-if="!pwdErr"
        >
          password minimal 8 karakter dan harus mengandung : huruf kecil, huruf
          besar, angka, dan karakter spesial
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Jenis Kelamin</v-col>
      <v-col cols="12" md="9">
        <v-radio-group v-model="gender" row hide-details="auto" class="mt-0">
          <v-radio label="Laki-laki" value="laki-laki"></v-radio>
          <v-radio label="Perempuan" value="perempuan"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Alamat</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="adress"
          hide-details="auto"
          label="Alamat"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">No Hp</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model.number="no_hp"
          hide-details="auto"
          label="No Hp"
          required
          dense
          @input="isInt(no_hp)"
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">Jabatan</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="posisi"
          hide-details="auto"
          label="Jabatan"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Tanggal Lahir</v-col>
      <v-col cols="12" md="9">
        <v-menu
          v-model="calendar1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="birth_date"
              label="Tanggal Lahir"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="birth_date"
            @input="calendar1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <footer class="d-flex justify-end my-5">
      <v-btn
        color="green"
        class="text-capitalize"
        dark
        v-if="!loading"
        @click="createItem"
        >Save</v-btn
      >
      <v-btn v-if="loading" color="green" class="text-capitalize" dark depressed
        ><v-progress-circular
          :size="25"
          class="mr-2"
          indeterminate
        ></v-progress-circular>
        Loading</v-btn
      >
    </footer>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "createTf",
  data() {
    return {
      name: "",
      password: "",
      email: "",
      no_hp: "",
      birth_date: "",
      gender: "",
      adress: "",
      posisi: "",
      calendar1: false,
      loading: false,
      emailErr: null,
      pwdErr: null,
    };
  },
  methods: {
    isInt(val) {
      if (val !== undefined) {
        if (Number.isInteger(parseInt(val)) == false) {
          setTimeout(() => {
            this.no_hp = "";
          }, 1);
        }
      }
    },
    createItem() {
      this.loading = true;
      let body = {
        nama_lengkap: this.name,
        email: this.email,
        password: this.password,
        no_hp: this.no_hp,
        tanggal_lahir: this.birth_date,
        jenis_kelamin: this.gender,
        alamat: this.adress,
        jabatan: this.posisi,
      };
      let data = {
        body: body,
        role: "tf",
      };
      this.$store
        .dispatch("user/createUser", data)
        .then((data) => {
          if (data.status == "success") {
            this.name = "";
            this.email = "";
            this.password = "";
            this.no_hp = "";
            this.birth_date = "";
            this.gender = "";
            this.adress = "";
            this.posisi = "";
            this.emailErr = null;
            this.pwdErr = null;
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          } else {
            this.emailErr = data.data.errors.email
              ? data.data.errors.email[0]
              : null;
            this.pwdErr = data.data.errors.password
              ? data.data.errors.password[0]
              : null;
            Swal.fire({
              icon: "error",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
          console.log(err.message);
        });
    },
  },
};
</script>

<style></style>
